import { Auth } from "aws-amplify";
import RestClient from '../../../src/AxiosConfig';
var resourceName = '/accessmanagement'

const getUserEmail = () => {
    let userAttributes = []
    let keys = Object.keys(localStorage)
    for (let i in keys) {
        if (keys[i].includes('userData')) {
            userAttributes = JSON.parse((localStorage.getItem(keys[i])))
            break;
        }
    }
    return userAttributes.UserAttributes["email"];
}

export const getModalInfo = (modalName) => {
    let config = JSON.parse(localStorage.getItem("modalConfig"))
    if (config) {

        for (let item of config) {

            if (item["Name"] === modalName) {
                return item;
            }
        }
        return {
            "ActiveIdleTimeInMinutes": 5,
            "DynamoDBKey": modalName,
            "IsEnabled": true,
            "Name": modalName
        };
    }
}

const AccessManagement = {
    async retrieveConfiguration() {
        try {

            const token = (await Auth.currentSession()).getIdToken().getJwtToken();
            RestClient.defaults.headers.common['Authorization'] = token;

            return await RestClient.get(resourceName, {
                params: {
                    "Action": "GetModalsConfiguration"
                }
            })
        }
        catch (error) {
            throw error
        }
    },

    async upsertModalEntry(modalName) {
        try {

            const token = (await Auth.currentSession()).getIdToken().getJwtToken();
            RestClient.defaults.headers.common['Authorization'] = token;

            let result = await RestClient({
                method: 'post',
                url: '/accessmanagement',
                data: {
                    "ModalName": modalName
                },
                params: {
                    'Action': 'UpsertModalUsage'
                }
            })

            if (result.status === 203) {
                return {
                    result,
                    status: false
                }
            } else {
                return {
                    result,
                    status: true
                }
            }
        }
        catch (error) {
            throw error
        }
    },

    async updateTimestamps(modalName) {
        try {

        } catch (error) {

        }
    },

    async deleteModalEntry(modalName) {
        try {

            const token = (await Auth.currentSession()).getIdToken().getJwtToken();
            RestClient.defaults.headers.common['Authorization'] = token;

            return await RestClient.delete(resourceName, {
                params: {
                    "Action": "RemoveModalUsage"
                },
                data: {
                    "ModalName": modalName
                }
            })
        }
        catch (error) {
            throw error
        }
    }

}

export default AccessManagement;